import React from "react";
import { observer } from "mobx-react";
import { useStores } from '../hooks/use-stores'
import "./Settings.scss";

const Settings = observer(() => {

  const { authStore, broadcastStore } = useStores();
  const orgId = authStore.user ? authStore.user.Organization.id : "" ;
  const playFabTitleId = authStore.user ? authStore.user.Organization.playfabId : "" 

  return (
    <div id="settings" className="panel">
      
      <div>
      <h2>SETTINGS</h2>
      <div>Organization id: { orgId }</div>
      <div>PlayFab title id: { playFabTitleId }</div>
      </div>

    </div>
  )
})

export default Settings
