import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { useStores } from "../hooks/use-stores";
import "./Login.scss";

const Login = observer((props) => {
  const { authStore, blockStore, portfolioStore, broadcastStore } = useStores();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const emailRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    authStore.getUser();

    document.body.addEventListener("keyup", handleKey);

    return () => {
      document.body.removeEventListener("keyup", handleKey);
    };
  }, []);

  function emailInputChanged(e) {
    setEmail(e.target.value);
  }

  function passwordInputChanged(e) {
    setPassword(e.target.value);
  }

  async function login() {
    if (!email || email.length < 5 || email.indexOf("@") === -1) {
      emailRef.current.focus();
      setFeedbackMessage("Invalid email.");
      return;
    }

    if (!password || password.length < 8) {
      passwordRef.current.focus();
      setFeedbackMessage("Invalid password.");
      return;
    }

    const response = await authStore.login(email, password);
    switch (response.statusText) {
      case "OK":
        let data = await response.json();
        authStore.setUser(data);
        authStore.setLoggedIn(true);
        blockStore.getBlocks();
        portfolioStore.getPlaylists();
        history.push("/");
        break;
      case "Unauthorized":
        setFeedbackMessage("Please check email and password.");
        break;
      default:
        setFeedbackMessage("Something went wrong. Please try again.");
        break;
    }
  }

  function handleKey(e) {
    if (e.key === "Enter") {
      login();
    }
  }

  if (!authStore.loggedIn) {
    return (
      <div id="login" className="panel" method="post">
        <img alt="Uplause Plus logo" src="./uplause_plus_logo_small.png"></img>

        <form>
          <label htmlFor="email">EMAIL</label>
          <input
            id="emailInput"
            ref={emailRef}
            type="text"
            name="email"
            value={email}
            onChange={emailInputChanged}
          ></input>

          <label htmlFor="password">PASSWORD</label>
          <input
            id="passwordInput"
            ref={passwordRef}
            type="password"
            name="password"
            value={password}
            onChange={passwordInputChanged}
          ></input>

          <div id="loginFeedback">{feedbackMessage}</div>

          <input
            type="button"
            className="panel extraLight"
            value="LOG IN"
            onClick={login}
          ></input>
        </form>
      </div>
    );
  }
});

export default Login;
