import React, { useEffect, useState, useCallback } from "react";
import "./_toggle.scss";

const Toggle = (props) => {

    const {title, name, disabled, onChange } = props;
    const [checked, setChecked] = useState(false);

    useEffect(() => {

        if(props.checked!==undefined) setChecked(props.checked);

    }, [props.checked]);

    const handleChange = (e) => {

        if(onChange) {
        onChange(!checked, e);
        }

    }

    return (

        <React.Fragment>

            <label className="toggle">
            <font className="label">{ title }</font>                    
            <input type="checkbox" name={ name } onChange={(e) => { handleChange(e) }} checked={ checked } disabled={ disabled }></input>

            <span className="slider round"></span>
            </label>   

        </React.Fragment>

    )

}

export default Toggle

/*
import React from "react";
import "./_toggle.scss";

class Toggle extends React.Component {

//------------------------
// CONSTRUCTOR

    constructor(props) {
    
        super();

    }

//------------------------
// RENDER

    render() {

        return (

            <React.Fragment>

                <label className="toggle">
                <font className="label">{ this.props.title }</font>                    
                <input type="checkbox" name={ this.props.name } checked={ false } onChange={(e) => { this.handleChange(e.currentTarget.checked, e) }} disabled={ this.props.disabled }></input>
                <span className="slider round"></span>
                </label>   

            </React.Fragment>

        )

    }

//------------

    handleChange(b, e) {

        if(this.props.onChange) {
        this.props.onChange(b, e);
        }

    }

}

export default Toggle
*/