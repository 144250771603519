import { toJS, action, observable, decorate } from "mobx";

import * as firebase from "firebase/app";
import "firebase/database";

class FirebaseStore {
  //----------------------------
  // VARIABLES -----------------

  database;

  //----------------------------
  // ACTIONS -----------------

  async init() {
    if (!this.database) {
      let config = {
        apiKey: "AIzaSyCh2PCAT6WDk_4ywbg_Fmg9sBd24i9iDCE",
        authDomain: "uplause.firebaseapp.com",
        databaseURL: "https://uplause-default-rtdb.firebaseio.com",
        storageBucket: "uplause.appspot.com",
      };

      if (this.database === undefined) {
        firebase.initializeApp(config);
        this.database = firebase.database();
      }
    }
  }

  //------------

  async sendEvent(eventPath, value) {
    console.log("send event? " + eventPath + " - " + value);
    this.database.ref(eventPath).set(value);
  }
}

decorate(FirebaseStore, {
  database: observable,

  init: action,
  sendEvent: action,
});

export default FirebaseStore;
