import { action, observable, decorate } from 'mobx';

class AuthStore {
  user = null;
  loggedIn = null;

  setLoggedIn(isLoggedIn) {
    this.loggedIn = isLoggedIn;
  }

  setUser(user) {
    this.user = user;
  }

  async getUser() {
    const url="/api/user";
    const response = await fetch(url, {credentials: "include"});
    if(!response.ok) {
      this.loggedIn = false;   
      return null;
    } else {
      let data = await response.json();
      this.user = data;
      this.loggedIn = true;      
      return this.user;
    }
  }

  async login(email, password) {
    const url = "/api/auth/login";
    const response = await fetch(
      url,
      {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type" : "application/json; charset=utf-8"
        },
        body: JSON.stringify({
          email,
          password
        })
      }
    )
    return response;
  }

  async logout(e) {
    let url="/api/auth/logout";
    let response = await fetch(url, {method: "get", credentials: "include"});
    if(!response.ok) {
      console.log("logout failed");
      console.log(response);
    } else {
      this.user = null;
      this.loggedIn = false;    
    }
  }
}
decorate(AuthStore, {
  user: observable,
  loggedIn: observable,
  setLoggedIn: action,
  setUser: action,
  getblocks: action,
  login: action,
  logout: action,
})

export default AuthStore;
