import React from "react";
import "./Header.scss";

class Header extends React.Component {

//------------------------
// CONSTRUCTOR

  /*
  constructor(props) {
  
    super(props);

  }
  */
  
//---------
  
  componentDidMount() {
  }

//------------------------
// RENDER

  render() {

    return (

      <div id="header" className="panel">
      <img alt="Uplause Plus Logo" src="./uplause_plus_logo_small.png"></img>
      </div>

    );

  }

//------------------------
// FUNCTIONS

}

export default Header
