import React from "react";

class StaticBlockEditor extends React.Component {

//------------------------
// CONSTRUCTOR

  constructor(props) {

    super(props);

    this.state={
    content:null
    }

  }

//---------

  componentDidMount() {

    this.setState({
    content:this.props.content
    });

  }

  componentDidUpdate(prevProps) {

    if(JSON.stringify(prevProps)!==JSON.stringify(this.props)) {

      this.setState({
      content:this.props.content
      });

    }

  }

//------------------------
// RENDER

  render() {

  // get content from props or create default content ---
    
    let c= this.state.content ? this.state.content : {} ;

  // render ---

    return (

      <div className="panel light">
      
        <form onChange={ (e) => { this.handleContentChange(e); }}>
        
          <label htmlFor="text">Text:</label>
          <textarea value={ c.text } name="text" disabled={ this.props.disabled }>
          </textarea>

        </form>

      </div>

    )

  }

//------------------------
// FUNCTIONS  

  handleContentChange(e) {

    let c=Object.assign({}, this.state.content);
    c.text=e.target.form.text.value;
    this.setState({content:c});

    this.props.handleContentChange({text:e.target.form.text.value});    

  }

}

export default StaticBlockEditor