import { action, observable, decorate } from 'mobx';

class BlockStore {
  blocks = [];

  setBlocks(blocks) {
    this.blocks = blocks
  }

  async getBlocks() {
    const url = "/api/blocks";
    const response = await fetch(url, {method:"GET", credentials:"include"});
    if(!response.ok) {
      return;
    }
    this.blocks = await response.json();
    return this.blocks;
  }

  async saveBlock(block) {
    const url="/api/block";
    // POST to save as new block
    // PUT to update existing block
    let method= block._id==null ? "POST" : "PUT" ;
    if(block._id==null) delete block._id;
    let response = await fetch(
      url, 
      {
        method:method, 
        credentials:"include", 
        headers: {"Content-Type":"application/json; charset=utf-8"},
        body: JSON.stringify(block)
      }
    );

    if(!response.ok) {
      console.log("saving block failed");
      console.log(response);
      return null;
    } else {
      let data=await response.json();
      return data;
    }
  }


  async deleteBlock(block) {
    let response = await fetch(
      "/api/block/"+block._id, 
      {
        method:"DELETE", 
        credentials:"include", 
        headers: {"Content-Type":"application/json; charset=utf-8"}
      }
    );

    if(!response.ok) {
      console.log("deleting block failed");
      console.log(response);
    }

    for(let i=0; i<this.blocks.length; i++) {
      if(block._id===this.blocks[i]._id) {
        this.blocks.splice(i, 1);
      }
    }
  }
}
decorate(BlockStore, {
  blocks: observable,
  getblocks: action,
  setBlocks: action,
  saveBlock: action,
  deleteBlock: action,
})

export default BlockStore;
