import React, { useState, useEffect, useCallback } from "react";

function numToStr(num, numLeadingZeroes) {

    let str=String(num);
    while(str.length<=numLeadingZeroes) str="0"+num;

return str;
}

const Countdown = (props) => {

    const { time } = props;
    const [sec, setSec] = useState(0);
    const [min, setMin] = useState(0);
    const [hours, setHours] = useState(0);
    const [days, setDays] = useState(0);
    const [timeStr, setTimeStr] = useState(null);

//---------

    const setTime = useCallback(() => {

        let t=time-Date.now();
    
        if(t>0) {
    
            let sec=t/1000;
            let min=Math.floor(sec/60);
            sec-=min*60;
            sec=Math.ceil(sec);
    
            let hours=Math.floor(min/60);
            min-=hours*60;
    
            let days=Math.floor(hours/24);
            hours-=days*24;
    
            setSec(sec);
            setMin(min);
            setHours(hours);
            setDays(days);
    
        } else {
    
            setSec(0);
            setMin(0);
            setHours(0);
            setDays(0);            
    
        }
    
    }, [time]);

//---------

    const getTimeStr = useCallback(() => {

        let str="";
        if(days>0) str+= days>1 ? days+" days and " : days+" day and ";
        str+=numToStr(hours, 1)+":"+numToStr(min, 1)+":"+numToStr(sec, 1);
        return str;

    }, [sec, min, hours, days]);

//---------

    useEffect(() => {

        setTime(time);
        let str=getTimeStr();
        setTimeStr(str);

        const interval = setInterval(() => {

            setTime(time);
            let str=getTimeStr();
            setTimeStr(str);


        }, 1000);

        return () => clearInterval(interval);

    }, [setTime, time, getTimeStr]);

    return (
    <React.Fragment>SCHEDULED TO START IN { timeStr }</React.Fragment>
    )

}

export default Countdown;