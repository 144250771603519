import React, { useState, useEffect } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useStores } from '../hooks/use-stores'
import "./Blocks.scss";

import StaticBlockEditor from "./StaticBlockEditor";
//import QuestionBlockEditor from "./QuestionBlockEditor";
import QuestionEditor from "./QuestionEditor";
import QuizBlockEditor from "./QuizBlockEditor";

const Blocks = observer(
  () => {
    const { authStore, blockStore, portfolioStore, firebaseStore } = useStores();
    const [selectedBlock, setSelectedBlock] = useState(null);
    const [disableChanges, setDisableChanges] = useState(false);
    const [isDirty, setDirty] = useState(false);
    const availableBlockTypes = ["static", "question", "quiz", "highscore", "questionnaire"];
    const availableBlockTypeTexts = ["Static", "Single Question", "Quiz", "Highscore", "Questionnaire"];

  //---------

    useEffect(() => {

      blockStore.getBlocks();

    }, []);

  //---------

    function renderEditorButtons() {

      if(selectedBlock===null) return;

      return (    

        <div className="buttonList horizontal contentEditorButtons">
        <img src="./loading.gif" alt="Loading ..." className="loading"></img>
        <input type="button" className={ !isDirty ? "button extraLight" : "button extralight highlighted" } value="Save" onClick={ (e) => { save(e) }} disabled={ !isDirty || disableChanges } />
        <input type="button" className={ !isDirty ? "button extraLight" : "button extralight highlighted" } value="Undo" onClick={ (e) => { undo(e) }} disabled={ !isDirty || disableChanges } />
        <input type="button" className="button extraLight" value="Duplicate" onClick={ (e) => { duplicate(e) }} disabled={ disableChanges } />
        <input type="button" className="button extraLight" value="Delete" onClick={ (e) => { deleteBlock(e) }} disabled={ disableChanges } />
        </div>

      )
    }

  //---------

    function renderItemButton(item, i) {

      let className="button extraLight";
      let id = selectedBlock === null ? null : selectedBlock._id ;
      if(item._id===id) className+=" active"
    
      return (

        <li key={ i }>
        <input type="button" className={ className } value={ item.name } onClick={ (e) => { selectBlock(item._id) }} disabled={ disableChanges } />
        </li>

      )

    }

  //---------

  function renderNewBlockButton() {

      let className="newBlockButton button extraLight";
      if(selectedBlock!==null && selectedBlock.isNew) className+=" active";

      return (
      
        <li key={ blockStore.blocks.length+1 }>
        <input type="button" className={ className } value="--- New block ---" onClick={ (e) => { selectNewBlock(e) }} disabled={ disableChanges } />
        </li>

      )

    }

  //-------------------
  // TYPE EDITOR ------

  function renderBlockTypeEditor() {

      let block=selectedBlock;
      if(block===null) {

        // no selected block and no new block, render nothing

      } else if(block!==null) {

        let name=block.name;

        return (

          <div className="nameAndTypeEditor panel light">
      
            <div className="nameEditor">
            <label htmlFor="name">Name: </label>
            <input type="text" name="name" value={ name } onChange={ (e) => { handleNameChange(e.target.value); }} disabled={ disableChanges }></input>
            </div>
      
            <div className="typeEditor">
            
              <label htmlFor="type">Type: </label>
              
              <select name="type" value={ block.type ? block.type : "" } onChange={ (e) => { e.target.blur(); handleTypeChange(e.target.value); }} disabled={ disableChanges }>

                <option key="0" value=""></option>

                { availableBlockTypes.map((t, i) =>

                  <option key={ i } value={ t }>{ availableBlockTypeTexts[i] }</option>
        
                )}              

              </select>
      
            </div>
      
          </div>
      
        )

      }

    }

  //---------

  function handleNameChange(value) {

    if(disableChanges) return;

    let block=Object.assign({}, selectedBlock);
    if(block===null) return;
    
    block.name=value;

    setSelectedBlock(block);
    setDirty(true);    

  }

//---------

  function handleTypeChange(value) {

    if(disableChanges) return;

    let block=Object.assign({}, selectedBlock);
    if(block===null) return;

    block.type=value;

    setSelectedBlock(block);
    setDirty(true);    

  }

//-------------------
// CONTENT EDITOR ---

  function renderBlockContentEditor() {

      let block=selectedBlock;
      if(block===null) return;

      switch(block.type) {

        case "static":
        return <StaticBlockEditor key={ block._id } content={ block.content } handleContentChange={ (e) => { handleContentChange(e) }} disabled={ disableChanges } />

        case "question":
        return <QuestionEditor key={ block._id } content={ block.content } canBeMinimized={ false } handleContentChange={ (e) => { handleContentChange(e) }} disabled={ disableChanges } />

        case "quiz":
        return <QuizBlockEditor key={ block._id } content={ block.content } handleContentChange={ (e) => { handleContentChange(e) }} disabled={ disableChanges } />

        default:
        break;

      }

    }

  //---------

  function handleContentChange(content) {

    if(disableChanges) return;

    let block=Object.assign({}, selectedBlock);
    if(block===null) return;

    block.content=content;
    setSelectedBlock(block);

    setDirty(true);

  }

//------------------------
// FUNCTIONS

  function selectBlock(id) {

    if(disableChanges) return;

    let block=null;

    for(let i=0; i<blockStore.blocks.length; i++) {

      if(blockStore.blocks[i]._id===id) {
      block=toJS(blockStore.blocks[i]);
      break;
      }
    
    }

    if(block===null) return;

    setSelectedBlock(block);

  }

//---------

  function selectNewBlock() {

    if(disableChanges) return;

    setSelectedBlock({
    name:"",
    content:{},
    isNew:true
    });

  }

//---------

  async function save(e) {
  
    let block=selectedBlock;
    if(block===null) return;
    
    if(disableChanges) return;
    setDisableChanges(true);

    let blocks = [...blockStore.blocks];

    // add as new block
    if(block._id===null) {

      blocks.push(block);

    // update existing block
    } else {

      for(let i=0; i<blocks.length; i++) {
      if(blocks[i]._id===block._id) blocks[i]=block;
      }

    }

  // save to database ---

    portfolioStore.updateBlock(block);
    let response= await blockStore.saveBlock(block);

    if(block.isNew) {
      blocks.push(response);
      block.isNew=false;
    }

    blockStore.setBlocks(blocks);
    setSelectedBlock(response);

    setDisableChanges(false);
    document.activeElement.blur();

  // send event to clients ---

    let pl=portfolioStore.playlists;
    let b;
    for(let i=0; i<pl.length; i++) {

      for(let j=0; j<pl[i].blocks.length; j++) {

        b=pl[i].blocks[j].block;
        if(block._id===b._id) {

          let eventPath=b.organizationId+"/"+pl[i]._id+"/update";
          firebaseStore.sendEvent(eventPath, Date.now());

        }

      }

    }

  // done ---

    setDirty(false);

  }

//---------

  function undo(e) {

      let block=selectedBlock;
      if(block===null) return;  
      selectBlock(block._id);
      setDirty(false);

    }

//---------

  function duplicate(e) {
      
      let block=selectedBlock;
      if(block===null) return;    

      setSelectedBlock({
      name:block.name,
      type:block.type,
      content:block.content,
      isNew:true
      })

    }

//---------

  async function deleteBlock(e) {

    if(disableChanges) return;
    setDisableChanges(true);

    let block=selectedBlock;
    if(block===null) return;    

    setSelectedBlock(null);
    portfolioStore.updateBlock(block, true);
    await blockStore.deleteBlock(block);

    setDisableChanges(false);
    document.activeElement.blur();

  }

//------------------------
// RENDER

    return (

      <div id="blocks">

        {
          blockStore.blocks && (
            <>
              <div id="blockSelector" className={ disableChanges ? "panel disabled" : "panel" }>

                <h2>BLOCKS</h2>

                <ul className="buttonList">

                  { 

                    blockStore.blocks.map((item, i) => 
                    renderItemButton(item, i)
                    )

                  }

                  { renderNewBlockButton() }

                </ul>            

              </div>

              <div id="blockEditor" className={`panel contentEditor ${ disableChanges ? "disabled" : "" }`}>

                <h2>BLOCK EDITOR</h2>

                { renderEditorButtons() }
                { renderBlockTypeEditor() }
                { renderBlockContentEditor() }

              </div>
            </>
          )
        }

      </div>

    )
  }
)

export default Blocks
