import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { toJS } from 'mobx';
import "./index.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom"
import { observer } from "mobx-react";
import Login from "./Login/Login";
import Logout from "./Login/Logout";
import Header from "./Header/Header";
import Portfolio from "./Portfolio/Portfolio";
import Blocks from "./Blocks/Blocks";
import Settings from "./Settings/Settings";
import Users from "./Users/Users";
import { useStores } from './hooks/use-stores'

const PrivateRoute = observer(({ component, ...rest }) => {
  const { authStore } = useStores();
  return (
    <Route
      {...rest}
      render = {(props) => (
          authStore.loggedIn ? (
            <div>      
              {React.createElement(component, props)}
            </div>
          ) : (
          <Redirect
            push
            to='/login'
          />
        ))
      }
    />
  )
});

const Home = () => {
  return (
    <div id="welcome" className="panel">Welcome to Uplause Plus.</div>
  )
}

const Main = observer(() => {

  const { authStore, blockStore, portfolioStore, firebaseStore, playfabStore } = useStores();
  
  useEffect(() => {

    async function init() {
    
      await authStore.getUser();

      if(authStore.loggedIn) {

        blockStore.getBlocks();
        portfolioStore.getPlaylists();
        firebaseStore.init();

        await playfabStore.init(authStore.user.Organization.playfabId);
        await playfabStore.loginWithCustomId(authStore.user.id);

      }

    }

    init();

  }, [authStore.loggedIn])

  if(authStore.loggedIn===null) {

    return (
      <div id="content"></div>
    )

  }

  return (
    <React.Fragment>

      {authStore.loggedIn && <Logout></Logout>}
      {authStore.loggedIn && <Header></Header>}

      <div id="content">

        <Router>
    
          {authStore.loggedIn && <nav id="navigation" className="panel">

            <h2>MAIN MENU</h2>

            <ul className="buttonList">
    
              <li>            
                <NavLink exact to="/portfolio" className="button extraLight" activeClassName="active">Portfolio</NavLink>
              </li>
              
              <li>            
                <NavLink exact to="/blocks" className="button extraLight" activeClassName="active">Blocks</NavLink>
              </li>
              
              <li>            
                <NavLink exact to="/settings" className="button extraLight" activeClassName="active">Settings</NavLink>
              </li>          

              <li>            
                <NavLink exact to="/users" className="button extraLight" activeClassName="active">Users</NavLink>
              </li>          

            </ul>

          </nav>}

          <Switch>
  
            <Route exact path="/login" component={Login}></Route>
            <PrivateRoute exact path="/portfolio" component={Portfolio}></PrivateRoute>
            <PrivateRoute exact path="/blocks" component={Blocks}></PrivateRoute>
            <PrivateRoute exact path="/settings" component={Settings}></PrivateRoute>
            <PrivateRoute exact path="/users" component={Users}></PrivateRoute>
            <PrivateRoute exact path="/" component={Home}>
            </PrivateRoute>
            <PrivateRoute exact path="*">
            </PrivateRoute>   
          
          </Switch>
        
        </Router>
      
      </div>

    </React.Fragment>
  )

})

//--------------------------------------------------------------
// DONE --------------------------------------------------------
//--------------------------------------------------------------

ReactDOM.render(
<Main />,
document.getElementById("root")
);