import React, { useEffect, useState } from "react";
import { action, toJS } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../hooks/use-stores";
import "./Users.scss";

const Users = observer(() => {
  //------------------------------------------
  // VARIABLES -------------------------------

  const { playfabStore } = useStores();
  const [leaderboard, setLeaderboard] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [leaderboardName, setLeaderboardName] = useState(null);
  const [leaderboardMaxResults, setLeaderboardMaxResults] = useState(null);
  const [secretKey, setSecretKey] = useState("");
  const [userSegments, setUserSegments] = useState(null);
  const [users, setUsers] = useState(null);

  const [fetchingUsers, setFetchingUsers]=useState(false);
  const [fetchingOneUser, setFetchingOneUser]=useState(false);

  const [leaderboardError, setLeaderboardError] = useState(null);
  const [userError, setUserError] = useState(null);
  const [secretKeyError, setSecretKeyError] = useState(null);
  const [requestError, setRequestError] = useState(null);

  const leaderboardNameTooltip=`
  1. Go to developer.playfab.com<br />
  2. Select your PlayFab title<br />
  3. Go to Leaderboards<br />
  4. Paste one of the leaderboard names here
  `;

  const secretKeyTooltip=`
  1. Go to developer.playfab.com<br />
  2. Select your PlayFab title<br />
  3. Go to title settings (the gear icon on the top left)<br />
  4. Go to "Secret Keys" and paste the secret key here
  `;

  //------------------------------------------
  // INITIALIZE ------------------------------

  useEffect(() => {
    
    let titleId=playfabStore.titleId;

    let LBName=localStorage.getItem(titleId+"_leaderboardName");
    if(LBName) setLeaderboardName(LBName);

    let LBMaxResults=localStorage.getItem(titleId+"_leaderboardMaxResults");
    if(LBMaxResults) setLeaderboardMaxResults(LBMaxResults);

    let secret = localStorage.getItem(titleId+"_secretKey");
    if (secret) setSecretKey(secret);

    async function init() {

      if (playfabStore.sessionTicket) {
        getLeaderboard(LBName, LBMaxResults);
      }

      if(secret) {
        getUserSegments(secret);
      }

    }

    init();

  }, [playfabStore.sessionTicket]);

  //------------------------------------------
  // RENDER ----------------------------------

  return (
    <React.Fragment>
      <div id="settings">
        <div className="panel">
          <h2>SETTINGS</h2>
          <form className="panel light">
            <label htmlFor="leaderboardNameInput">Leaderboard name:</label>
            <input
              type="text"
              name="leaderboardNameInput"
              className="leaderboardNameInput"
              defaultValue={leaderboardName}
            />
            &nbsp;&nbsp;&nbsp;<Tooltip message={ leaderboardNameTooltip } />
            <label htmlFor="leaderboardNameInput">Leaderboard max results:</label>
            <input
              type="text"
              name="leaderboardMaxResultsInput"
              className="leaderboardMaxResultsInput"
              defaultValue={leaderboardMaxResults}
            />
            <label htmlFor="secretKeyInput">PlayFab secret key:</label>
            <input
              type="text"
              name="secretKeyInput"
              className="secretKeyInput"
              defaultValue={secretKey}
            />
            &nbsp;&nbsp;&nbsp;<Tooltip message={secretKeyTooltip} />
            { secretKey && secretKeyError && (<span className="errorMessage">&nbsp;&nbsp;&nbsp;There's something wrong with this secret key. Error message: {secretKeyError}</span>)}
            <br/>
            <input
              type="button"
              value="SAVE"
              className="panel extraLight"
              onClick={(e) => {
                saveSettings(e);
              }}
            />
          </form>
        </div>
      </div>

      <div id="users">
        <div className="leaderboard panel">
          <h2>LEADERBOARD ({leaderboardName})</h2>
          {renderLeaderboard()}
        </div>
        <div className="userEditor panel">
          <h2>SELECTED USER</h2>
            <div className="panel light">
              {renderUserEditor()}
            </div>
          </div>
        <div className="allUsers panel">
          <h2>DOWNLOAD USER INFO AS CSV</h2>
            <div className="panel light">
              { renderUserList() }
            </div>
          </div>
      </div>
    </React.Fragment>
  );

  //------------------------------------------
  // COMPONENTS ------------------------------

  function Tooltip(props) {

    let tooltip=null;

    const ShowTooltip = (e) => {

      tooltip=document.createElement("p");
      tooltip.innerHTML=props.message;
      tooltip.className="tooltipContent panel extraLight";
      e.currentTarget.appendChild(tooltip);
    
    };
  
    const HideTooltip = (e) => {

      if(tooltip!=null) {
      tooltip.parentNode.removeChild(tooltip);
      tooltip=null;
      }

    };
  
    return (

      <span className="tooltip" onMouseEnter={(e) => { ShowTooltip(e) }} onMouseLeave={(e) => { HideTooltip(e) }}>?</span>

    );

  }

  //------------------------------------------
  // FUNCTIONS -------------------------------

  function saveSettings(e) {

    let titleId=playfabStore.titleId;

    let LBName=e.target.parentNode.leaderboardNameInput.value;
    let LBMaxResults=e.target.parentNode.leaderboardMaxResultsInput.value;
    if(LBName!==leaderboardName || LBMaxResults!==leaderboardMaxResults) getLeaderboard(LBName, LBMaxResults);
    
    setLeaderboardName(LBName);
    localStorage.setItem(titleId+"_leaderboardName", LBName);

    setLeaderboardMaxResults(LBMaxResults);
    localStorage.setItem(titleId+"_leaderboardMaxResults", LBMaxResults);

    let sk = e.target.parentNode.secretKeyInput.value;
    if(secretKey!==sk) getUserSegments(sk);
    setSecretKey(sk);
    localStorage.setItem(titleId+"_secretKey", sk);

  }

  //------------

  async function getLeaderboard(name, maxResults) {

    if(!name) name=leaderboardName;
    if(!maxResults) maxResults=leaderboardMaxResults;

    setLeaderboard(null);
    setLeaderboardError(null);
  
    let data = await playfabStore.getHighscores(name, maxResults);
    if(!data.error) {
      setLeaderboard(data.data.Leaderboard);
      setLeaderboardError(null);
    } else {
      setLeaderboardError(data.errorMessage);
    }

  }

  function renderLeaderboard() {

    if(!leaderboardName || leaderboardName.length===0 || leaderboardError) {

      if(!leaderboardName || leaderboardName.length===0) {

        return (
          <React.Fragment>
            <p>Missing leaderboard name.</p>
          </React.Fragment>
        ); 

      } else if(leaderboardError) {

        return (
          <React.Fragment>
            <p className="errorMssage">Something's wrong with the leaderboard. Error message: {leaderboardError}</p>
          </React.Fragment>
        );   

      }

    }

    if(!leaderboard) { 
      return (
        <React.Fragment>
          <p>Loading...</p>
        </React.Fragment>
      ); 
    }

    if(leaderboard.length>0) {

      return (
        <React.Fragment>
          <div className="panel light">
            <ul>
              <li className="title">
                <span>Position</span>
                <span>Display name</span>
                <span>Score</span>
              </li>

              {leaderboard &&
                leaderboard.map((item, i) => (
                  <li
                    key={i}
                  >
                    <span>{item.Position + 1}.</span>
                    <span>{item.DisplayName}</span>
                    <span>{item.StatValue}</span>
                    &nbsp;&nbsp;&nbsp;<input type="button" value="SELECT" className="panel extraLight" onClick={(e) => { selectUser(item.PlayFabId); }}/>
                  </li>
                ))}
            </ul>
            <br />
            {leaderboard && <input type="button" className="panel extraLight" value="REFRESH" onClick={(e)=>{ getLeaderboard(null, null); }} />}
          </div>
        </React.Fragment>
      );

    } else {
      
      return (
        <React.Fragment>
          <div className="panel light">
            <p>
            Leaderboard has no entries
            <br />
            or it does not exist
            (make sure that the leaderboard name is correct in the settings).
            </p>
          </div>
        </React.Fragment>
      );

    }

  }

  //------------

  async function getUserSegments(secretKey_) {

    setFetchingUsers(false);

    if(!secretKey_) secretKey_=secretKey;
    if(!secretKey_) return;

    let data = await playfabStore.getAllUserSegments(secretKey_);

    if (!data.error) {
      setUserSegments(data.data.Segments);
      setSecretKeyError(null);
    } else {
      setUserSegments(null);
      setSecretKeyError(data.errorMessage);
    }

  }

  //------------

  async function getUsers(e) {

    setFetchingUsers(true);
    setRequestError(null);
    setUsers(null);

    if (!secretKey || secretKey.length < 5) return;
    let segment = e.target.parentNode.playerSegmentSelect.value;
    let data = await playfabStore.getAllUsers(secretKey, segment);
    if (!data.error) {
      setUsers(data.data.PlayerProfiles);
    } else {
      setRequestError(data.errorMessage);
    }

    setFetchingUsers(false);

  }

  //------------

  function downloadUsersCSV() {
    if (!users) return;

    let csv = "Id,Display Name,Email,First login,Last login,Login service,Country,City\n";
    for (let i = 0; i < users.length; i++) {
      csv += users[i].PlayerId + ",";
      csv += users[i].DisplayName ? users[i].DisplayName+"," : ",";

      csv +=
        users[i].ContactEmailAddresses.length > 0
          ? users[i].ContactEmailAddresses[0].EmailAddress+","
          : ",";

      csv += users[i].Created + ",";

      csv += users[i].LastLogin + ",";

      csv += users[i].Origination + ",";

      csv += users[i].Locations.LastLogin.CountryCode + ",";

      csv += users[i].Locations.LastLogin.City + ",";

      csv += "\n";
    }

    let a = document.createElement("a");
    a.href = "data:attachment/csv," + encodeURIComponent(csv);
    a.target = "_blank";
    a.download = "users.csv";

    document.body.appendChild(a);
    a.click();
  
  }

  //------------

  function renderUserList() {

    if(!secretKey || secretKeyError) {

      if(!secretKey) {

        return (
          <React.Fragment>
            <p>Set the PlayFab secret key in the settings to use this feature.</p>
          </React.Fragment>
        );

      } else if(secretKeyError) {

        return (
          <React.Fragment>
            <p className="errorMessage">Something is wrong with the secret key. Error message: {secretKeyError}</p>
          </React.Fragment>
        );

      }

    }

    if(userSegments && userSegments.length>0) {

      let defaultValue=null;

      for(let i=0; i<userSegments.length; i++) {
        if(userSegments[i].Name==="All Players") {
          defaultValue=userSegments[i].Id;
          break;
        }
      }

      return (
        <React.Fragment>
          <form>
            <label htmlFor="playerSegmentSelect">Select a user segment:</label>
            <select name="playerSegmentSelect" defaultValue={defaultValue}>
              {userSegments &&
                userSegments.map((item, i) => (
                  <option key={i} value={item.Id}>
                    {item.Name}
                  </option>
                ))}
            </select>
            &nbsp;
            <input
              type="button"
              value="GET"
              className="panel extraLight"
              onClick={(e) => {
                getUsers(e);
              }}
            ></input>
            &nbsp;
            {requestError && (
              <span className="errorMessage">&nbsp;&nbsp;&nbsp;{requestError}</span>
            )}
            {fetchingUsers && (
              <span>&nbsp;&nbsp;&nbsp;Loading...</span>
            )}
            {!fetchingUsers && users && (
              <input
                type="button"
                value="DOWNLOAD CSV"
                className="panel extraLight"
                onClick={(e) => {
                  downloadUsersCSV();
                }}
              />
            )}
          </form>
        </React.Fragment>
      );

    } else {

      return (
        <React.Fragment>
          <p>Loading...</p>
        </React.Fragment>
      );

    }

  }

  //------------

  async function selectUser(id) {

    setFetchingOneUser(true);

    let data = await playfabStore.getOneUser(secretKey, id);
    console.log(data);
    if (!data.error) {

      let user={...data.data.Data};
      user.id=id;
      setSelectedUser(user);
      setUserError(null);

    } else {
      setSelectedUser(null);
      setUserError(data.errorMessage);
    }

    setFetchingOneUser(false);

  }

  function renderUserEditor(item) {

    if(fetchingOneUser) {

      return (
        <p>Loading ...</p>
      );

    }

    if(userError) {

      return (
        <p className="errorMessage">Something went wrong. Error message: {userError}</p>
      );

    }

    if(!selectedUser) {

      return (
        <p>Select a user from the leaderboard.</p>
      );

    } 
    
    if(selectedUser) {

      let name= selectedUser.name && selectedUser.name.Value ? selectedUser.name.Value : "" ;
      let displayName= selectedUser.userName && selectedUser.userName.Value ? selectedUser.userName.Value : "" ;
      let email= selectedUser.email && selectedUser.email.Value ? selectedUser.email.Value : "" ;
      let phone= selectedUser.phone && selectedUser.phone.Value ? selectedUser.phone.Value : "" ;

      return (
        
        <form>

          <ul>

            <li>
              <span className="title">Name</span>
              <input type="text" name="nameInput" defaultValue={name} disabled />
              { /*&nbsp;&nbsp;&nbsp;<input type="button" value="EDIT" className="panel extraLight" onClick={(e) => { editUserName(e, this); }} />*/ }
            </li>

            <li>
              <span className="title">Display name</span>
              <input type="text" name="displayNameInput" defaultValue={displayName} disabled />
              &nbsp;&nbsp;&nbsp;
              <input type="button" name="displayNameEditButton" value="EDIT" className="panel extraLight" onClick={(e) => { editUserName(e, true); }} />
              <input type="button" name="displayNameSaveButton" value="SAVE" className="panel extraLight" style={{display:"none"}} onClick={(e) => { saveUserName(e); }} />
              &nbsp;
              <input type="button" name="displayNameCancelButton" value="CANCEL" className="panel extraLight" style={{display:"none"}} onClick={(e) => { editUserName(e, false); }} />
            </li>

            <li>
              <span className="title">Email</span>
              <input type="text" name="emailInput" defaultValue={email} disabled />
              { /*&nbsp;&nbsp;&nbsp;<input type="button" value="EDIT" className="panel extraLight" /*onClick={(e) => { editUserDetail(e, "emailInput"); }} />*/ }
            </li>

            <li>
              <span className="title">Phone</span>
              <input type="text" name="phoneInput" defaultValue={phone} disabled />
              { /*&nbsp;&nbsp;&nbsp;<input type="button" value="EDIT" className="panel extraLight" /*onClick={(e) => { editUserDetail(e, "phoneInput"); }} />*/ }
            </li>

          </ul>

        </form>

      );

    }

  }

  //------------

  async function editUserName(e, makeVisible) {

    e.currentTarget.form.displayNameEditButton.style.display= makeVisible ? "none" : "inline" ;
    e.currentTarget.form.displayNameSaveButton.style.display= makeVisible ? "inline" : "none" ;
    e.currentTarget.form.displayNameCancelButton.style.display= makeVisible ? "inline" : "none" ;

    let input=e.currentTarget.form["displayNameInput"];
    input.disabled=!makeVisible;

  }

  async function saveUserName(e) {

    let input=e.currentTarget.form["displayNameInput"];
    let newName=input.value;
    input.disabled=true;

    e.currentTarget.form.displayNameEditButton.style.display="inline";
    e.currentTarget.form.displayNameSaveButton.style.display="none";
    e.currentTarget.form.displayNameCancelButton.style.display="none";

    await playfabStore.setUserName(secretKey, selectedUser.id, newName);

    let lb=[...leaderboard];
    for(let i=0; i<lb.length; i++) {

      if(lb[i].PlayFabId===selectedUser.id) {
        lb[i].DisplayName=newName;
      }

    }

    setLeaderboard(lb);

  }

});

export default Users;
