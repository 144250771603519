import React, { useEffect, useState, useCallback } from "react";
import "./_draggableList.scss";

const DraggableList = (props) => {

    const { children, className, onChange, willRefreshOnChange } = props;
    const [dragTarget, setDragTarget] = useState(null);
    const [placeholder, setPlaceholder] = useState(null);
    const [dragPosition, setDragPosition] = useState(0);
    const [dragOffset, setDragOffset] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [originalIndex, setOriginalIndex] = useState(-1);
    const [elemCSSPosition, setElemCSSPosition] = useState(null);

    const nonDraggableElems=["INPUT", "TEXTAREA", "SPAN"];

//------------

    useEffect(() => {

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("mousemove", doDrag);
        window.addEventListener("mouseup", endDrag);

        return () => {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("mousemove", doDrag);
        window.removeEventListener("mouseup", endDrag);
        }

    });

//------------

    const setDragPos = useCallback((dragTarget_, dragPosition_, dragOffset_, placeholder_) => {

        if(dragTarget!=null || dragTarget_!=null) {

            let dt= dragTarget_ ? dragTarget_ : dragTarget ;
            let ph= placeholder_ ? placeholder_ : placeholder ;
            let dragPos= dragPosition_ ? dragPosition_ : dragPosition ;            
            let parent=dt.parentNode;

            let dragRect=dt.getBoundingClientRect();

            let parentRect=parent.getBoundingClientRect();
            let y=dragPos-(scrollPosition+parentRect.top);
            y-= dragOffset_ ? dragOffset_ : dragOffset ;

            let childNodes=parent.childNodes;
            let otherItems=[];

            for(let i=0; i<childNodes.length; i++) {
            if(childNodes[i]!==dt && childNodes[i]!==ph) { otherItems.push(childNodes[i]); }
            }

            let dragY=y+dragRect.height/2;

            if(dragY<dragRect.height) {

                parent.prepend(ph);

            } else if(dragY>parentRect.height-dragRect.height) {

                parent.append(ph);
            
            } else {

                let rect;
                let itemY;

                for(let i=0; i<otherItems.length; i++) {

                    rect=otherItems[i].getBoundingClientRect();
                    itemY=rect.top-parentRect.top;

                    if(dragY>itemY && dragY<itemY+rect.height) {

                        if(y<itemY) {
                        parent.insertBefore(placeholder, otherItems[i].nextSibling);
                        break;

                        } else {
                        parent.insertBefore(placeholder, otherItems[i]);
                        break;
                        }

                    }

                }

            }

            dt.style.top=y+"px";

        }

    }, [scrollPosition, dragPosition, dragOffset, dragTarget, placeholder]);

//------------

    const startDrag = (e) => {

        if(nonDraggableElems.includes(e.target.tagName)) return;
        if(children.length<=1) return;

        e.preventDefault();
        e.stopPropagation();

        let elem=e.currentTarget;
        let rect=elem.getBoundingClientRect();        

        let index=[...elem.parentNode.children].indexOf(elem);
        setOriginalIndex(index);

        let kids=elem.parentNode.childNodes;
        if(kids.length===0) return;

        setElemCSSPosition(elem.style.position);
        elem.style.position="absolute";
        elem.className="selected";
        setDragTarget(elem);

        let offsetY=e.pageY-(scrollPosition+rect.top);
        setDragOffset(offsetY);

        let ph=document.createElement("li");
        ph.style.height=rect.height+"px";
        elem.parentNode.insertBefore(ph, elem);
        setPlaceholder(ph);

        setDragPosition(e.pageY);
        setDragPos(elem, e.pageY, offsetY, ph);

        if(window.pageYOffset) {
        window.pageYOffset=scrollPosition;
        } else {
        document.documentElement.scrollTop=scrollPosition;
        }

    }

//------------

    const doDrag = (e) => {

        if(dragTarget) {

            setDragPosition(e.pageY);
            setDragPos();

        }

    }

//------------

    const handleScroll = (e) => {

        let scrollTop=document.documentElement.scrollTop;
        let dif=scrollTop-scrollPosition;
        let y=dragPosition+dif;

        setScrollPosition(scrollTop);
        setDragPosition(y);
        setDragPos();

    }

//------------

    const endDrag = (e) => {

        e.preventDefault();
        e.stopPropagation();

        if(!dragTarget) return;

        let elem=dragTarget;   
        let parent=elem.parentNode;     

        // set list item order now if onChange will not re-render it
        if(!willRefreshOnChange) parent.insertBefore(elem, placeholder);

        elem.style.position=elemCSSPosition;
        elem.style.top="auto";
        elem.className="";

        let otherChildren=[];
        for(let i=0; i<parent.children.length; i++) {
        if(parent.children[i]!==dragTarget) otherChildren.push(parent.children[i]);
        }

        //let newIndex=[...parent.children].indexOf(placeholder);
        let newIndex=[...otherChildren].indexOf(placeholder);

        setDragTarget(null);
        setDragPosition(0);
        setDragOffset(0);

        parent.removeChild(placeholder);
        setPlaceholder(null);

        onChange(originalIndex, newIndex);

    }

//------------

    return (

        <React.Fragment>

            <ul className={ "draggable " + ( className ? className : "" ) } onScroll={(e) => { handleScroll(e) }} >

                {

                    children && children.map(function(child, i) {
                    
                        return (

                            <li id={ i } index={ i } key={ i } onMouseDown={(e) => { startDrag(e) }}>    
                            {child}
                            </li>

                        )

                    })

                }

            </ul>

        </React.Fragment>

    )

}

export default DraggableList