import React, { useState, useEffect, useCallback } from "react";
import "./PlaylistItem.scss";

function getHours(duration) {
  duration = duration ? duration / 1000 : 0;
  return Math.floor(duration / 3600);
}

function getMinutes(duration) {
  duration = duration ? duration / 1000 : 0;
  return Math.floor(duration / 60) % 60;
}

function getSeconds(duration) {
  duration = duration ? duration / 1000 : 0;
  return Math.floor(duration) % 60;
}

function formatNumber(number) {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
}

const useTimeRemaining = (playlistItem, setActive) => {

  let now=Date.now();
  let end=new Date(playlistItem.start).getTime()+playlistItem.duration;
  const [timeRemaining, setTimeRemaining] = useState(end-now);

  useEffect(() => {
    const timeout = setTimeout(() => {
      now=Date.now();
      setTimeRemaining(end-now)
    }, 1000);
    return () => {
      clearTimeout(timeout)
      if (timeRemaining < 1000) {
      //setActive();
      }
    };
  }, [playlistItem, timeRemaining])

  return timeRemaining;
}

const Timer = ({playlistItem, setActive}) => {
  const timeRemaining = useTimeRemaining(playlistItem, setActive);
  if (timeRemaining <= 0) {
    return "00:00:00";
  }
  const hours = getHours(timeRemaining);
  const minutes = getMinutes(timeRemaining);
  const seconds = getSeconds(timeRemaining);
  return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(seconds)}`;
}

const PlaylistItem = (props) => {
  const { 
    playlistItem, 
    index, 
    onDurationChange, 
    onPlayListItemDelete, 
    onSetActive, 
    status, 
    disableChanges
  } = props;

  const [duration, setDuration] = useState(5000);

  const setHours = useCallback((hours) => {
  
    let h=getHours(duration);
    let d=duration-h*60*60*1000;
    d+=hours*60*60*1000;
    setDuration(d);
    onDurationChange(d, index);

  }, [duration, index, onDurationChange]);

  const setMinutes = useCallback((min) => {

    let m=getMinutes(duration);
    let d=duration-m*60*1000;
    d+=min*60*1000;
    setDuration(d);
    onDurationChange(d, index);

  }, [duration, index, onDurationChange]);

  const setSeconds = useCallback((sec) => {

    let s=getSeconds(duration);
    let d=duration-s*1000;
    d+=sec*1000;
    setDuration(d);
    onDurationChange(d, index);

  }, [duration, index, onDurationChange]);

  useEffect(() => {

    setDuration( playlistItem.duration ? playlistItem.duration : 5000 );

  }, [playlistItem.duration])

  return (
    <>
    <div className={ disableChanges ? "playlistItem-container disabled" : "playlistItem-container" }>
      <div className="pointer-container">
        
        { status === "ready" && (
          /* "jump to" button commented out for now because we can't demo it without the broadcast feature */          
          <div></div>
          /*<input type="button" className="pointer" onClick={() => {onSetActiveAndSave(index)}} value={ index === 0 ? "start now" : "jump to"}></input>*/
        ) }

        {
          status === "active" && (
            <div className="pointer active"><Timer playlistItem={playlistItem} setActive={() => {onSetActive(index + 1)}} /> <span className="arrow"></span> </div>
          )
        }

      </div>
      <div className="panel light playlist-item" key={index} style={{
        padding: "1rem",
      }}>
        <h2>{playlistItem.block.name}</h2>
        <div className="controls">
          <div>
            <input type="button" disabled={disableChanges} className="panel extraLight" value="Remove" onClick={() => {onPlayListItemDelete(index)}}></input>
          </div>
          <form className="duration-form">
            <label>Duration:</label>
            <div className="duration-input">
              <input 
                type="number"
                disabled={disableChanges}
                min="0" 
                max="99" 
                value={getHours(duration)} 
                onChange={(e) => {setHours(e.target.value)}}
              ></input> Hours
            </div>
            <div className="duration-input">
              <input 
                type="number" 
                disabled={disableChanges}
                min="0" 
                max="59" 
                value={getMinutes(duration)} 
                onChange={(e) => {setMinutes(e.target.value)}}
              ></input> Minutes
            </div>
            <div className="duration-input">
              <input 
                type="number" 
                disabled={disableChanges}
                min="0" 
                max="59" 
                value={getSeconds(duration)} 
                onChange={(e) => {setSeconds(e.target.value)}}
              ></input> Seconds
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
  )
}

export default PlaylistItem;