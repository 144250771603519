import React, { useState, useEffect, useRef, useCallback } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useStores } from '../hooks/use-stores';
import "./Portfolio.scss";
import PlaylistItem from './PlaylistItem';
import Countdown from '../Components/Countdown';
import DraggableList from '../Components/DraggableList';

const Portfolio = observer(
  () => {
    const { portfolioStore, blockStore, firebaseStore } = useStores();
    const [newBlock, setNewBlock] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [isPlaying, setPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(Date.now());
    const [disableChanges, setDisableChanges] = useState(false);
    const selectEl = useRef(null);

//---------

  const setTimes = useCallback((t) => {

    let tempTime;

    if(!t) {
    t=startTime.getTime();
    } else {
    t= typeof(t)==="number" ? t : t.getTime() ;
    }

    tempTime=t;
    if(!tempTime) return;

    for (let i = 0; i < portfolioStore.playlists[0].blocks.length; i++) {
      
      portfolioStore.setStartTime(new Date(tempTime), i);
      //portfolioStore.playlists[0].blocks[i].start=new Date(tempTime);

      tempTime+=parseInt(portfolioStore.playlists[0].blocks[i].duration);
      portfolioStore.setEndTime(new Date(tempTime), i);
      //portfolioStore.playlists[0].blocks[i].end=new Date(tempTime);

    }

  }, [portfolioStore, startTime]); 
  
//---------

  const getPlaylistTotalDuration = useCallback((pl) => {

    if(!pl) pl=portfolioStore.playlists[0];
    if(!pl) return 0;

    for(let i=0; i<pl.blocks.length; i++) {
    
      if(!pl.blocks[i].duration) {  
      
        let start=pl.blocks[i].start;
        let end=pl.blocks[i].end;
        let d= !start || !end ? 5000 : (end-start)/1000 ;
        if(isNaN(d)) d=5000;
        pl.blocks[i].duration=d;

      }

    }

    let duration = pl.blocks
    .map(item => item.duration)
    .reduce((prev, curr) => prev + curr, 0);      

    return duration;

  }, [portfolioStore.playlists]);

//---------

  // check if first block has a start time
  // use that to schedule the entire playlist if start time + block durations is in the future
  useEffect(() => {

    const pl=portfolioStore.playlists[0];
    if(pl && pl.blocks.length>0 && pl.blocks[0].start!==null) {

      let duration=getPlaylistTotalDuration(pl);
      let t1= pl.blocks[0].start ? new Date(pl.blocks[0].start) : null ;
      let t2= t1!==null ? new Date(t1.getTime()+duration) : null ;
      let now=Date.now();

      if(t1!==null && t2!==null) {

        t1=t1.getTime();
        t2=t2.getTime();
        if(t1>now || t2>now) setTimes(t1);
        if(now>=t1 && now<=t2) setPlaying(true);
        t1=new Date(t1);
      
      }

      setStartTime(t1);

    }

  }, [portfolioStore.playlists])  

  // keep track of current time ---
  useEffect(() => {

    const timeout = setTimeout(() => {

      setCurrentTime(Date.now());

      const pl=portfolioStore.playlists[0];
      if(pl && pl.blocks.length>0) {

        let t1=new Date(pl.blocks[0].start).getTime();
        let t2=t1+getPlaylistTotalDuration();
        let now=Date.now();

        // set list to playing if current time between start time and end time
        if(now>=t1 && now<=t2) {
        setPlaying(true);
        } else if(isPlaying) {
        setPlaying(false);
        }

        if(Date.now()>t2) setStartTime(null);

      }

    }, 1000);

    return () => {
    clearTimeout(timeout)
    };

  }, [currentTime, portfolioStore.playlists, getPlaylistTotalDuration, isPlaying])
  //}, [currentTime, portfolioStore.playlists, getPlaylistTotalDuration, isPlaying])

//---------

  function handleBlockSelectChange(e) {
    setNewBlock(e.target.value);
  }

  function handleNameChange(e) {
    portfolioStore.setName(e.target.value);
  }

  function handleTypeChange(e) {
    portfolioStore.setType(e.target.value);
  }

  function handleListSort(originalIndex, newIndex) {
    portfolioStore.changeBlockIndex(originalIndex, newIndex);
  }

  function handleSubmit(e) {
    console.log(e);
    e.preventDefault();
    if (!newBlock) {
      return;
    }
    const block = blockStore.blocks.find(block => {
      return block._id === newBlock
    })
    portfolioStore.addBlock(block);
    selectEl.current.selectedIndex = 0;
    setNewBlock(null);
  }

  function handleDurationChange(duration, i) {
    portfolioStore.setDuration(duration, i);
  }

  function handlePlaylistItemDelete(index) {
    portfolioStore.removeBlock(index);
    document.activeElement.blur();
  }

  async function savePlaylist(disable) {
    
    if(disable) setDisableChanges(true);
    await portfolioStore.savePlaylist(portfolioStore.playlists[0]);
    if(disable) setDisableChanges(false);
    document.activeElement.blur();

    let pl=portfolioStore.playlists[0];
    let eventPath=pl.organizationId+"/"+pl._id+"/update";
    firebaseStore.sendEvent(eventPath, Date.now());

  }

  async function deletePlaylist() {
    await portfolioStore.deletePlaylist(portfolioStore.playlists[0]);
  }

  async function undoChanges(disable) {
    portfolioStore.undoChanges();
    document.activeElement.blur();    
  }

  function getBlockStatus(item, index) {

    let activeBlock=getActiveBlock();
    if(item===activeBlock) return "active";
    if(new Date(item.start).getTime()>Date.now()) return "ready";
    return "inactive";

  }

  async function handleStartTimeChange(e) {

    let start = new Date(e.target.value);
    if(start.getTime()<Date.now()) start=null;
    setStartTime(start);
    if(start!==null) setTimes(start);

  }

  async function resetPlaylist() {
    setDisableChanges(false);
    setStartTime(null);
    setPlaying(false);
    portfolioStore.playlists[0].blocks.forEach(block => {
      block.isActive = false;
      block.start = null;
      block.end = null;
    });
    portfolioStore.isSaved=false;
    document.activeElement.blur();
    await savePlaylist();
  }

  async function activatePlaylist() {

    let d=new Date(Date.now());
    setStartTime(d);
    setTimes(d);
    setPlaying(true);
    await savePlaylist();  

  }

  function getActiveBlock(pl) {

    let b=null;

    if(!pl) pl=portfolioStore.playlists[0];
    if(!pl) return null;

    let now=Date.now();
    let t1, t2;
    for(let i=0; i<pl.blocks.length; i++) {

      t1=pl.blocks[i].start;
      if(t1===null) continue;

      t1=new Date(t1).getTime();
      t2=t1+pl.blocks[i].duration;

      if(now>=t1 && now<=t2) {
      b=pl.blocks[i];
      }

    }

    return b;

  }

  function getFormattedDate(date) {

    if(date===null) return "";

    function pad(number) {
      if (number < 10) {
        return '0' + number;
      }
      return number;
    }

      return date.getUTCFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes())
  }

  function isPlaylistActive() {

    if(startTime===null) return false;

    let t1=startTime.getTime();
    let t2=startTime.getTime()+getPlaylistTotalDuration();
    if(t1<=Date.now() && t2>=Date.now()) return true;
    return false;

  }

//------------------------
// RENDER

  return (
    <div className="page-content">

      {/* <div className="panel">

        <div>
          <h2>BLOCKS LIBRARY</h2>
          <div>
            <ul>
              {blockStore.blocks.map((block, i) => {
                return (
                  <li key={i}><div className="panel">{ block.name }</div></li>
                )
              })}
            </ul>
          </div>
        </div>

      </div> */}

      {
        portfolioStore.playlists[0] && (
          <>
            <div className="panel contentEditor">

              <div className={ disableChanges || isPlaying ? "content-header disabled" : "content-header" }>
                <h2>CONTENT EDITOR</h2>
                <span>

                  <input 
                  className={ portfolioStore.isSaved ? "extraLight panel" : "highlighted panel" } 
                  type="button" 
                  value="Save" 
                  onClick={ (e) => { savePlaylist(true) }} 
                  disabled={ disableChanges || isPlaying }>
                  </input>
                
                  <input 
                  className="highlighted panel" 
                  style={ portfolioStore.isSaved ? { "display": "none" } : { "display":"inline" } } 
                  type="button" 
                  value="Undo" 
                  onClick={ (e) => { undoChanges(true) }} 
                  disabled={ disableChanges || isPlaying }>
                  </input>

                </span>
              </div>

              <div className={ disableChanges || isPlaying ? "panel light editor disabled" :  "panel light editor" } style={{
                padding: "1rem",
              }}>
                <label>
                  Name:
                  <input type="text" readOnly={disableChanges || isPlaying} onChange={handleNameChange} value={portfolioStore.playlists[0].name ? portfolioStore.playlists[0].name : ""} disabled={ disableChanges }></input>
                </label>
                <label>
                  Type:
                  <input type="text" readOnly={disableChanges || isPlaying} onChange={handleTypeChange} value={portfolioStore.playlists[0].type ? portfolioStore.playlists[0].type : ""} disabled={ disableChanges }></input>
                </label>
              </div>
              <div className="panel">
                <div className="set-time-container" style={{
                  display: "flex",
                  alignItems: "center",
                }}>
                  <div className="pointer-container">
                    
                    { !isPlaylistActive() && (
                      <input type="button" className="pointer" onClick={activatePlaylist} value="START NOW" disabled={ disableChanges }></input>
                    ) }

                  </div>
                  <div className="panel light" style={{
                    marginBottom: "0.5rem",
                    display: "flex",
                    flexGrow: "1",
                    justifyContent: "space-between",
                    padding: "0.75rem 0.5rem"
                  }}>
                    <input type="button" className="panel extraLight" value="Reset" onClick={resetPlaylist} style={{
                      marginTop: "0",
                    }} disabled={ disableChanges }></input>

                    { (startTime!==null && startTime.getTime()>Date.now()) && ( 
                      <h2 className="countdown"><Countdown time={ startTime }>Starts in </Countdown></h2>
                    ) }

                    <div>
                      <label>
                        Start:
                        <input type="datetime-local" disabled={ disableChanges || isPlaying } onChange={handleStartTimeChange} value={getFormattedDate(startTime)}></input>
                      </label>
                    </div>
                  </div>
                </div>
                <DraggableList onChange={handleListSort} willRefreshOnChange={true} style={{marginBottom: "4px"}}>
                  {portfolioStore.playlists[0].blocks && portfolioStore.playlists[0].blocks.map((playlistItem, index) => (
                      <PlaylistItem 
                        key={index} 
                        playlistItem={playlistItem} 
                        index={index}
                        duration={playlistItem.duration}
                        onDurationChange={handleDurationChange}
                        onPlayListItemDelete={handlePlaylistItemDelete}
                        //onSetActive={handleSetActive}
                        //onSetActiveAndSave={setActiveAndSave}
                        status={getBlockStatus(playlistItem, index)}
                        disableChanges={ disableChanges || isPlaying }
                      ></PlaylistItem>
                    ))
                  }
                </DraggableList>
                <div className={ disableChanges || isPlaying ? "block-dropzone disabled" : "block-dropzone" }>
                  <form onSubmit={handleSubmit}>
                    <label htmlFor="block-select">ADD A BLOCK</label>
                    <select disabled={disableChanges || isPlaying} ref={selectEl} name="blocks" id="block-select" onChange={handleBlockSelectChange}>
                      <option value=""></option>
                      {blockStore.blocks.map((block, i) => {
                        return (
                          <option key={i} value={block._id}>{ block.name }</option>
                        )
                      })}
                    </select>
                    <input 
                      type="submit" 
                      disabled={ disableChanges || isPlaying }
                      value="Add" 
                      className="panel extraLight"
                      style={{
                        padding: "6px 1rem",
                      }}
                    ></input>
                  </form>
                </div>
              </div>
            </div>
          </>

        )
      }


    </div>
  )
  }
)

export default Portfolio
