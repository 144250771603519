import React from "react";
import Toggle from "../Components/Toggle";
import DraggableList from '../Components/DraggableList';

class OptionEditor extends React.Component {

//------------------------
// CONSTRUCTOR

    /*
    constructor(props) {

        super(props);

    }
    */

//---------
  
  componentDidMount() {

  }

//------------------------
// RENDER

  render() {

    return (

        <div className="panel optionEditor">
        
            { this.props.children }
            <input type="button" className="addItemButton" onClick={ (e) => { this.addOption(); }} disabled={ this.props.disabled }></input>            
            { this.renderOptions() }

        </div>

    )

  }

//------------------------
// FUNCTIONS  

    renderOptions() {

        let opts=this.props.options;
        if(!opts) return;

        return (

            <DraggableList className="optionList" onChange={(originalIndex, newIndex) => { this.handleListSort(originalIndex, newIndex); }} willRefreshOnChange={true}>

            { 

                opts.map((item, i) => 
                this.renderOption(i, item)
                )

            } 

            </DraggableList>

        )

    }

//---------

    renderOption(iter, opt) {

        return (

            <React.Fragment key={ iter }>

                <span className="optionTitle">Option { iter+1 }</span>

                <input className={ "optionTextInput text_"+iter } type="text" value={ opt.text } onChange={(e) => { this.handleOptionTextChange(e, opt); }} disabled={ this.props.disabled }></input>

                <input className="removeButton" type="button" onClick={ (e) => { this.removeOption(opt.id) }} disabled={ this.props.disabled }></input>                

                <span className="correctAnswerToggle">
                <Toggle title="Correct:" checked={ opt.correctAnswer } onChange={(b, e) => { this.handleOptionCorrectAnswerChange(e, opt); }} disabled={ this.props.disabled }></Toggle>
                </span>                

            </React.Fragment>

        )

    }

//---------

    handleListSort(originalIndex, newIndex) {

        let opts=[...this.props.options];
        let o=Object.assign({}, opts[originalIndex]);

        opts.splice(originalIndex, 1);
        opts.splice(newIndex, 0, o);

        this.setState({options:opts});
        this.props.onChange(opts);

    }

//---------

    handleOptionTextChange(e, opt) {

        let opts=[...this.props.options];
        for(let i=0; i<opts.length; i++) {
        if(opts[i].id===opt.id) opts[i].text=e.target.value;
        }   

        this.setState({options:opts});
        this.props.onChange(opts);

    }

    handleOptionCorrectAnswerChange(e, opt) {

        let opts=[...this.props.options];
        for(let i=0; i<opts.length; i++) {
        if(opts[i].id===opt.id) opts[i].correctAnswer=e.target.checked;
        }   

        this.setState({options:opts});
        this.props.onChange(opts);

    }

//---------

    addOption() {

        let opts= this.props.options ? [...this.props.options] : [] ;
        let o={ id:opts.length, text:"", correctAnswer:false};
        opts.push(o);

        this.props.onChange(opts);

    }

//---------

    removeOption(id) {
    
        let opts=[...this.props.options];
        for(let i=0; i<opts.length; i++) {

            if(opts[i].id===id) {
            opts.splice(i, 1);
            break;
            }

        }

        this.setState({options:opts});
        this.props.onChange(opts);

    }

}

export default OptionEditor