import { toJS, action, observable, decorate } from "mobx";

class PlayfabStore {
  //----------------------------
  // VARIABLES -----------------

  titleId;
  secretKey;
  sessionTicket;
  userId;

  //----------------------------
  // ACTIONS -------------------

  async init(titleId) {
    this.titleId = titleId;

    return true;
  }

  //------------

  async loginWithCustomId(id) {
    // quit if already logged in
    if (this.sessionTicket !== undefined) return;
    this.sessionTicket = null;

    let response = await fetch(
      "https://" + this.titleId + ".playfabapi.com/Client/LoginWithCustomID",
      {
        method: "post",
        headers: { "Content-Type": "application/json;charset=utf-8" },

        body: JSON.stringify({
          CustomId: id,
          TitleId: this.titleId,
          CreateAccount: true,
        }),
      }
    );

    let data = await response.json();

    if (data.status === "OK") {
      this.sessionTicket = data.data.SessionTicket;
      this.userId = data.data.PlayFabId;
    } else {
      this.sessionTicket = null;
    }

    return data.data;
  }

  //------------

  async getAllUserSegments(secretKey) {
    console.log("get user sgments?");
    let response = await fetch(
      "https://" + this.titleId + ".playfabapi.com/Server/GetAllSegments",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-SecretKey": secretKey,
        },
      }
    );

    let data = await response.json();
    console.log(data);
    return data;
  }

  //------------

  async getAllUsers(secretKey, segmentId) {
    let response = await fetch(
      "https://" + this.titleId + ".playfabapi.com/Server/GetPlayersInSegment",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-SecretKey": secretKey,
        },

        body: JSON.stringify({
          SegmentId: segmentId,
        }),
      }
    );

    let data = await response.json();
    return data;
  }

  //------------

  async getOneUser(secretKey, playerId) {

    let response = await fetch(
      "https://" + this.titleId + ".playfabapi.com/Server/GetUserData",
      {
        method:"post",
        headers: {
          "Content-type":"application/json;charset=utf-8",
          "X-SecretKey":secretKey
        },

        body: JSON.stringify({
          PlayFabId:playerId,
          Keys: ["userName"]
        })

      }
    );

    let data = await response.json();
    console.log(data);
    return data;

  }

  //------------

  async getHighscores(leaderboardName="main", maxResultsCount) {
    let response = await fetch(
      "https://" + this.titleId + ".playfabapi.com/Client/GetLeaderboard",
      {
        method: "post",

        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-Authentication": this.sessionTicket,
        },

        body: JSON.stringify({
          StartPosition: 0,
          StatisticName: leaderboardName,
          MaxResultsCount: maxResultsCount,
        }),
      }
    );

    let data = await response.json();
    return data;
  }

  //------------

  async setUserName(secretKey, playerId, name) {

    await fetch(
      "https://" +
        this.titleId +
        ".playfabapi.com/Admin/UpdateUserTitleDisplayName",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-SecretKey": secretKey,
        },

        body: JSON.stringify({
          DisplayName: name,
          PlayFabId: playerId,
        }),
      }
    );

    await fetch(
      "https://" +
        this.titleId +
        ".playfabapi.com/Admin/UpdateUserData",
      {      
        method: "post",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "X-SecretKey": secretKey,
        },

        body: JSON.stringify({
          PlayFabId: playerId,
          Data: {"userName":name}
        }),
      }
    );

  }

}

decorate(PlayfabStore, {
  secretKey: observable,
  sessionTicket: observable,
  userId: observable,

  init: action,
  loginWithCustomId: action,
  getHighscores: action,
  setUserName: action,
  getAllUserSegments: action,
  getAllUsers: action,
  getOneUser:action,
});

export default PlayfabStore;
