import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import "./QuizBlockEditor.scss";

import QuestionEditor from "./QuestionEditor";
import DraggableList from '../Components/DraggableList';

const QuizBlockEditor = observer((props) => {

    const { content } = props;
    //const [content, setContent] = useState([])

    useEffect(() => {

        if(!content.questions) content.questions=[];

    }, [props])

//------------------------
// RENDER

        return (

            <div className="quizBlockEditor">

                <div className="panel">
                <h2>INTRODUCTION</h2>

                    <form onChange={ (e) => { 

                        let o={
                        "title":e.currentTarget.title.value,
                        "message":e.currentTarget.message.value
                        };

                        handleIntroductionContentChange(o);

                    }}>

                        <span>
                        <label htmlFor="title">Title:</label>              
                        <input type="text" name="title" defaultValue={ content.introduction && content.introduction.title ? content.introduction.title : "" }></input>
                        </span>

                        <span>
                        <label htmlFor="message">Message:</label> 
                        <textarea name="message" defaultValue={ content.introduction && content.introduction.message ? content.introduction.message : "" }></textarea>
                        </span>

                    </form>

                </div>

                <div className="panel">
                <h2>QUESTIONS</h2>
                
                    <DraggableList onChange={handleQuestionListSort} willRefreshOnChange={true}>

                        {

                            content.questions!==undefined && content.questions.map(function(q, i) {
                            
                                return (
              
                                    <QuestionEditor key={ i } index={ i } content={ q } isMaximized={ false } handleContentChange={(e) => handleQuestionContentChange(e) } handleDelete={(index) => handleDelete(index) }>{ (i+1)+". "+( q.questionText ? q.questionText : "" ) }</QuestionEditor>

                                )

                            })

                        }

                    </DraggableList>
    
                    <div style={{ "position":"relative", "height":"45px" }}>
                    <input type="button" className="addItemButton" style={{ "position":"relative", "float":"right" }} onClick={(e) => addQuestion(e) }></input>
                    </div>

                </div>

                <div className="panel">
                <h2>RESULTS</h2>

                    <form onChange={ (e) => { 

                        let o={
                        "title":e.currentTarget.title.value,
                        "message":e.currentTarget.message.value
                        };

                        handleResultsContentChange(o);

                    }}>

                        <span>
                        <label htmlFor="title">Title:</label>              
                        <input type="text" name="title" defaultValue={ content.results && content.results.title ? content.results.title : "" }></input>
                        </span>

                        <span>
                        <label htmlFor="message">Message:</label> 
                        <textarea name="message" defaultValue={ content.results && content.results.message ? content.results.message : "" }></textarea>
                        </span>

                    </form>

                </div>

            </div>

        )

//-----------------------
// FUNCTIONS    

        function addQuestion(e) {

            let c=Object.assign({}, content);
            c.questions.push({});
            props.handleContentChange(c);  
            
        }

//------------

        function handleIntroductionContentChange(e) {
        
            let c=Object.assign({}, content);
            c.introduction=e;
            props.handleContentChange(c);  

        }

//------------

        function handleQuestionListSort(originalIndex, newIndex) {

            let c=Object.assign({}, content);

            let qs=[...c.questions];
            let q=Object.assign({}, qs[originalIndex]);
            qs.splice(originalIndex, 1);
            qs.splice(newIndex, 0, q);

            for(let i=0; i<qs.length; i++) {
            qs[i].index=i;
            }

            c.questions=qs;

            props.handleContentChange(c);  

        }

//------------

        function handleQuestionContentChange(e) {

            let c=Object.assign({}, content);
            c.questions[e.index]=e;
            props.handleContentChange(c);  

        }

//------------

        function handleResultsContentChange(e) {
            
            let c=Object.assign({}, content);
            c.results=e;
            props.handleContentChange(c);  

        }

//------------

        function handleDelete(index) {

            let c=Object.assign({}, content);
            c.questions.splice(index, 1);
            props.handleContentChange(c);  
        
        }

    }   

)

export default QuizBlockEditor