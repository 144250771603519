import React from "react";
import OptionEditor from "./OptionEditor";
import Toggle from "../Components/Toggle";
import "./QuestionEditor.scss";

class QuestionEditor extends React.Component {

//------------------------
// CONSTRUCTOR

  constructor(props) {

    super();

    this.state={
    content:null
    }

  }

//---------
  
  componentDidMount() {

    this.setState({
    content:this.props.content
    });

  }

  componentDidUpdate(prevProps) {

    if(JSON.stringify(prevProps)!==JSON.stringify(this.props)) {

      this.setState({
      content:this.props.content
      });

    }

  }

//------------------------
// RENDER

  render() {

  // get content from props or create default content ---
    
    let c= this.state.content ? this.state.content : {} ;
    let collapseState= this.props.isMaximized ? "maximized" : "minimized" ;

  // render ---

    return (

      <div className={ this.props.canBeMinimized!==false ? "questionEditor panel light "+collapseState : "questionEditor panel light" }>

        { this.props.canBeMinimized!==false &&

            <div className="header">
            <h3>{this.props.children}</h3>
            <input type="button" className="minimizeButton" onClick={ (e) => { this.maximizePanel(e) }}></input>
            </div>

        }

        <form onChange={ (e) => { this.handleContentChange(e); }}>

          <div className="section">

            <div className="column content">

              <label htmlFor="questionText">Question text:</label>
              <textarea name="questionText" value={ c.questionText } disabled={ this.props.disabled }></textarea>

            </div>

            <div className="column settings">

              <div className="section">

                <div className="column">

                    {/*
                    <span>
                    <label htmlFor="scoreType">Score type: </label>
                    <select name="scoreType" disabled={ this.props.disabled }>
                    <option value="what"></option>
                    <option value="is"></option>
                    <option value="this"></option>
                    </select>
                    </span>
                    */}

                    <span>
                    <Toggle title="Scoring: " name="scoringEnabled" checked={ c.scoringEnabled } onChange={(b, e) => { this.toggleScoring(b, e) }} disabled={ this.props.disabled }></Toggle>
                    </span>

                    <span className={ c.scoringEnabled ? "" : "disabled" }>
                    <label htmlFor="correctAnswerScore">Correct answer score:</label>
                    <input name="correctAnswerScore" type="number" defaultValue={ c.correctAnswerScore } disabled={ this.props.disabled || !c.scoringEnabled }></input>
                    </span>

                    <span className={ c.scoringEnabled ? "" : "disabled" }>
                    <label htmlFor="wrongAnswerScore">Wrong answer score:</label>
                    <input name="wrongAnswerScore" type="number" defaultValue={ c.wrongAnswerScore } disabled={ this.props.disabled || !c.scoringEnabled }></input>
                    </span>

                </div>

                <div className="column">

                    <span>
                    <Toggle title="Timing: " name="timingEnabled" checked={ c.timingEnabled } onChange={(b, e) => { this.toggleTiming(b, e) }}  disabled={ this.props.disabled }></Toggle>
                    </span>

                    <span className={ c.timingEnabled ? "" : "disabled" }>
                    <label htmlFor="timeToAnswer">Duration (sec):</label>
                    <input name="timeToAnswer" type="number" defaultValue={ c.timeToAnswer } disabled={ this.props.disabled || !c.timingEnabled }></input>
                    </span>

                    <span className={ c.timingEnabled ? "" : "disabled" }>
                    <label htmlFor="timeScore">Time bonus:</label>
                    <input name="timeScore" type="number" defaultValue={ c.timeScore } disabled={ this.props.disabled || !c.timingEnabled }></input>
                    </span>

                </div>

              </div>

            </div>

          </div>

          <OptionEditor options={ c.options } onChange={ (e) => { this.handleOptionsChange(e); }} disabled={ this.props.disabled }><h2>OPTIONS</h2></OptionEditor>

          <div className="section">

            <div className="column">

              <span>
              <label htmlFor="correctAnswerMessage">Correct answer message:</label>
              <input name="correctAnswerMessage" type="text" defaultValue={ c.correctAnswerMessage } disabled={ this.props.disabled }></input>
              </span>

              <span>
              <label htmlFor="wrongAnswerMessage">Wrong answer message:</label>
              <input name="wrongAnswerMessage" type="text" defaultValue={ c.wrongAnswerMessage } disabled={ this.props.disabled }></input>
              </span>

            </div>

            <div className="column">

              <span className={ c.timingEnabled ? "" : "disabled" }>
              <label htmlFor="outOfTimeMessage">Out of time message:</label>
              <input name="outOfTimeMessage" type="text" defaultValue={ c.outOfTimeMessage } disabled={ this.props.disabled || !c.timingEnabled }></input>
              </span>

            </div>

          </div>

          { this.props.handleDelete &&

            <div className="controllers section">
            <input type="button" className="button extraLight" value="Delete" onClick={(index) => { this.handleDelete(index); }}></input>
            </div>

          }

        </form>

      </div>

    )

  }
  
//------------------------
// FUNCTIONS  

  toggleScoring(b, e) {

    let c=Object.assign({}, this.state.content);
    c.scoringEnabled=b;
    this.setState({content:c});

    this.handleContentChange(e);

  }

//------------

  toggleTiming(b, e) {

    let c=Object.assign({}, this.state.content);
    c.timingEnabled=b;
    this.setState({content:c});

    this.handleContentChange(e);

  }

//------------

  handleContentChange(e) {

    let content=Object.assign({}, this.state.content);

    let form=e.target.form;
    for(let i=0; i<form.elements.length; i++) {

      if(form.elements[i].name.length>0) {

        switch(form.elements[i].type) {

          default:
          content[form.elements[i].name]=form.elements[i].value;
          break;

          case "checkbox":
          content[form.elements[i].name]=form.elements[i].checked;
          break;
        
        }

      }

    }

    content.index=this.props.index;

    this.props.handleContentChange(content);    
    this.setState({content:content});

  }

//------------

    maximizePanel(e) {

        let panel=e.target.closest(".panel");
        let isMinimized=panel.className.indexOf("minimized")>-1;
        let c=panel.className;

        if(isMinimized) {
        c=panel.className.replace("minimized", "maximized");
        } else {
        c=panel.className.replace("maximized", "minimized");
        }

        panel.className=c;

    }

//------------

  handleOptionsChange(e) {

    let content=Object.assign({}, this.state.content);
    content.index=this.props.index;
    content.options=e;

    if(this.props.handleContentChange) {
    this.props.handleContentChange(content);    
    }

    this.setState({content:content});

  } 

//------------

  handleDelete(e) {

    if(this.props.handleDelete) {
    this.props.handleDelete(this.props.index);
    }

  }

}

export default QuestionEditor