import React from 'react'
import { 
  PortfolioStore,
  BlockStore,
  AuthStore,
  FirebaseStore,
  PlayfabStore
} from '../stores'

export const storesContext = React.createContext({
  portfolioStore: new PortfolioStore(),
  blockStore: new BlockStore(),
  authStore: new AuthStore(),
  firebaseStore: new FirebaseStore(),
  playfabStore: new PlayfabStore()
})