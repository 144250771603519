import React from "react";
import { observer } from "mobx-react";
import { useStores } from '../hooks/use-stores'
import "./Login.scss";

const Logout = observer((props) => {

  const { authStore } = useStores();

  async function logout() {

    await authStore.logout();
    authStore.setLoggedIn(false);

  }

  return (
    <input id="logout" type="button" value="LOG OUT" onClick={logout}></input>
  );

})

export default Logout
